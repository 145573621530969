@font-face {
  font-family: 'Redaction';
  src: url('/public/fonts/Redaction.ttf') format('truetype');
  font-weight: normal;
 font-style: normal;
}

html, body {
  font-family: 'Redaction';
}
