html {
  overflow-x: hidden;
}

.screen-warning {
  display: none;
  text-align: center;
  padding: 20px;
  background-color: #FDD92B;
  color: black;
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 1000;
  text-transform:uppercase;
}

@media screen and (max-width: 599px) {
  .screen-warning {
    display: block;
  }
}

.style-link {
  text-decoration: none;
  color: #2b2a29;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #2b2a29;
}

::-webkit-scrollbar-thumb:hover {
  background: #2b2a29;
}

.container-navbar {
  flex-grow: 1;
}

.relative-positioned-div {
  position: relative;
}

.absolute-top-right {
  position: absolute;
  top: -20%;
  right: -2.5%;
  animation: pulse 4s infinite;
  width:15%
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.logo{
  width:100%;
}

.description{
  line-height:1.1rem;
}

.logos{
  position:fixed;
  bottom:0rem;
}

.images {
  max-width: 2.6rem;
}

.news{
  position: fixed;
  width: 18rem;
  top: 5rem;
  right: 1%;
  z-index:100;

}

.news-window {
  background-color: white;
  padding: 0.8rem;
  margin-top:1rem;
  border: 2px solid #2b2a29;
  position: relative;
}

.x{
  width:1.5rem;
}

.news-paragraph{
  margin-top:0.8rem;
  margin-bottom:0.2rem;
  line-height:1.2rem;
}

.close-button {
  position: absolute;
  top: 4%;
  right: 4%;
  border: none;
  padding:0px;
  background: none;
  cursor: pointer;
}

.my-btn {
  padding: 0.3rem 1rem;
  width: 18rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: white;
  color: #2b2a29;
  font-size: 1.3rem;
}

.submitButton {
  text-align: left;
}

.submitButtonSmall{
  text-align: left;
  padding: 0.3rem 1rem;
  width: 12rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: white;
  color: #2b2a29;
  font-size: 1.2rem;
}

.submitButtonMobile{
  text-align: left;
  padding: 0.3rem 1rem;
  width: 8rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: white;
  color: #2b2a29;
  font-size: 1rem;
}


.submitButton:hover {
  padding: 0.3rem 1rem;
  width: 18rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: #2b2a29;
  color: white;
}

.submitButtonSmall:hover{
  padding: 0.3rem 1rem;
  width: 12rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: #2b2a29;
  color: white;
}

.submitButtonMobile:hover{
  padding: 0.3rem 1rem;
  width: 8rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: #2b2a29;
  color: white;
}

.submitButton:hover svg path {
  fill: white;
}

.submitButtonSmall:hover svg path {
  fill: white;
}

.backButton {
  text-align: right;
}

.backButtonSmall{
  text-align: right;
  padding: 0.3rem 1rem;
  width: 12rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: white;
  color: #2b2a29;
  font-size: 1.2rem;
}

.backButtonMobile{
  text-align: right;
  padding: 0.3rem 1rem;
  width: 8rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: white;
  color: #2b2a29;
  font-size: 1rem;
}

.backButton:hover {
  padding: 0.3rem 1rem;
  width: 18rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: #2b2a29;
  color: white;
}

.backButtonSmall:hover {
  padding: 0.3rem 1rem;
  width: 12rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: #2b2a29;
  color: white;
}

.backButtonMobile:hover {
  padding: 0.3rem 1rem;
  width: 8rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: #2b2a29;
  color: white;
}


.button-select {
  min-width: 10rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: white;
  color: #2b2a29;
  font-size: 1rem;
  margin: 0 auto;
  display: block;
}

.button-select:hover {
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: #2b2a29;
  color: white;
  font-size: 1rem;
}

#choose-file {
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  background-color: white;
  color: gray;
  width: 18rem;
}

#text-area {
  border: 2px solid #2b2a29;
  border-radius: 0rem;
  background-color: white;
  width: 25rem;
}

.random-table {
  border: none;
  background: none;
  text-decoration: underline;
  font-size: 0.9rem;
}

.containers-margin {
  margin: 10%;
  border: 2px solid #2b2a29;
}

.draggable {
  padding: 0.6rem 1rem;
  max-width: 14rem;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  text-align: left;
  background-color: #2b2a29;
  color: white;
  cursor: pointer;
  margin: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.box-draggable {
  border: 2px solid #2b2a29;
  overflow: auto;
  height: 20rem;
}

.box-droppable {
  height: 20rem;
  border: 2px solid #2b2a29;
  overflow: auto;
}


.droppable {
  min-height: 3rem;
  min-width: 12rem;
  max-width: 16rem;
  border: 2px solid #2b2a29;
  text-align: center;
  background-color: white;
  color: #2b2a29;
  cursor: pointer;
}

.flex {
  display: flex;
  flex-direction: column;
}

.dropzone-padding {
  flex-grow: 1;
}

.padding-bottom {
  padding-bottom: 2rem;
  margin-bottom: 1.5rem;
}

.no-margin {
  margin: 0px;
}

.modifiers {
  border: 2px solid #2b2a29;
  min-width: 20rem;
  font-size: 1.2rem;
}

.custom-range.form-control {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.2rem;
  background-color: transparent;
  -webkit-appearance: none;
  border: none;
  transition: none;
}

.custom-range.form-control:focus {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.2rem;
  background-color: transparent;
  -webkit-appearance: none;
  border: none;
  transition: none;
  box-shadow: none;
}

.custom-range.form-control::-webkit-slider-thumb {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
  background-color: #2b2a29;
  width: 1.2rem;
  height: 1.2rem;
  border: none;
  border-radius: 0;
  cursor: pointer;
}

.custom-range.form-control::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
  background-color: white;
  border: 2px solid #2b2a29;
  border-radius: 0;
  cursor: pointer;
  height: 1.4rem;
}

.custom-number.form-control {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.5rem;
  background-color: transparent;
  -webkit-appearance: none;
  border: none;
  transition: none;
  border: 2px solid #2b2a29;
  border-radius: 0;
}

.custom-number.form-control:focus {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.2rem;
  background-color: transparent;
  -webkit-appearance: none;
  border: none;
  transition: none;
  border: 2px solid #2b2a29;
  border-radius: 0;
  box-shadow: none;
}

input[type="radio"] {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
  background-color: white;
  width: 1.3rem;
  height: 1.3rem;
  border: 2px solid #2b2a29;
  border-radius: 0;
  cursor: pointer;
  border-color: #2b2a29;
}

input[type="radio"]:checked {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
  background-color: #2b2a29;
  width: 1.3rem;
  height: 1.3rem;
  border: 2px solid #2b2a29;
  border-radius: 0;
  cursor: pointer;
  border-color: #2b2a29;
}

.custom-color.form-control-color {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
  background-color: none;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  border: 2px solid #2b2a29;
  border-radius: 0;
  cursor: pointer;
}

.custom-color.form-control-color:focus {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
  background-color: #2b2a29;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  border: none;
  border-radius: 0;
  cursor: pointer;
}

.export-name-camera{
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 25rem;
  height:2rem;
  background-color: none;
}

.camera-button{
  background-color:white;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.camera-button:hover{
  background-color:#2b2a29;
  border: 2px solid #2b2a29;
  border-radius: 100rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.camera-button:hover svg path {
  fill: white;
}

.shape-name-input {
  padding: 0.5rem;
  height: 2.8rem;
  width: 15rem;
  background-color: none;
  color: #2b2a29;
  font-size: 1.2rem;
  border: 2px solid #2b2a29;
  text-align: center;
}

.shape-name-input::placeholder {
  color: #2b2a29;
  opacity: 0.5;
}

.rule {
  opacity: 0.8;
  border-top: 2px solid #2b2a29;
}

.resources-text {
  max-height: 75vh;
  overflow-y: auto;
}

.border-black {
  border: 2px solid #2b2a29;
  overflow-y: auto;
}
